import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HowToReg from "@mui/icons-material/HowToReg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
export const RedirectBox = () => {
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(10);
    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prev) => {
                if (prev === 1) {
                    clearInterval(interval);
                    navigate("/dashboard", { replace: true });
                }
                return prev - 1;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [navigate]);
    return (<Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" textAlign="center" sx={(theme) => ({ minHeight: theme.spacing(64) })}>
      <HowToReg sx={{ fontSize: 80, mb: 2 }}/>
      <Typography variant="h5" sx={{ mb: 1 }}>
        <FormattedMessage id="setup.complete" defaultMessage="Setup is completed!"/>
      </Typography>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        <FormattedMessage id="setup.redirect" defaultMessage="Goes to Argus dashboard in {countdown}" values={{ countdown: <strong>{countdown}</strong> }}/>
      </Typography>

      <Button variant="contained" color="secondary" onClick={() => navigate("/dashboard")} sx={{ textTransform: "uppercase" }}>
        ARGUS DASHBOARD
      </Button>
    </Box>);
};
