import React from "react";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import Link from "@mnemonic/mui5/Link";
import { AuthenticationMethodsList } from "../../Components/AuthenticationMethodsList";
import { VerifyBox } from "../../Components/Login/Layout/Verify/VerifyBox";
import RedirectWrapper from "../Login/RedirectWrapper";
import { useAuthentication } from "./useAuthenticate";
import { messages } from "./utils";
export const AuthenticatePage = () => {
    const { methods, isLoading } = useAuthentication();
    return (<RedirectWrapper>
      <VerifyBox loading={isLoading}>
        <Typography variant="h5">
          <FormattedMessage {...messages.loginUsing}/>
        </Typography>
        <AuthenticationMethodsList methods={methods}/>
        <Box display="flex" justifyContent="center">
          <Link component={NavLink} to={{ pathname: `/enroll/activate` }}>
            <FormattedMessage id="login.verify.code" defaultMessage="Verify user and password"/>
          </Link>
        </Box>
      </VerifyBox>
    </RedirectWrapper>);
};
