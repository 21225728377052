import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Circle from "@mui/icons-material/Circle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { useFetchBreachStatusMutation } from "../Redux/Service/authenticateApi";
const minLengthRequirement = 8;
export const PasswordChecker = ({ newPassword = "", sessionKey }) => {
    const [checkPassword] = useFetchBreachStatusMutation();
    const [passwordValid, setPasswordValid] = useState(null);
    const isMinLengthMet = newPassword?.length >= 8;
    const [debouncedPassword, setDebouncedPassword] = useState(newPassword);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedPassword(newPassword);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [newPassword]);
    useEffect(() => {
        checkPasswordValidity(debouncedPassword);
    }, [debouncedPassword]);
    const checkPasswordValidity = async (password) => {
        try {
            const result = await checkPassword({ criteria: { password }, metaOpts: { sessionKey } }).unwrap();
            setPasswordValid(result?.data);
        }
        catch (error) {
            setPasswordValid(null);
        }
    };
    return (<Box display="flex" flexDirection="column" sx={({ spacing }) => ({ mt: spacing(2) })}>
      <Box display="flex" alignItems="center" mb={1}>
        {isMinLengthMet ? (<CheckCircleIcon color={isMinLengthMet ? "success" : "disabled"} sx={{ mr: 1 }}/>) : (<Circle color="disabled" sx={{ mr: 1 }}/>)}
        <Typography variant="body1">
          <FormattedMessage id="password.lengthRequirement" defaultMessage="At least {minLength} characters long" values={{ minLength: minLengthRequirement }}/>
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        {passwordValid?.state === "ok" ? (<CheckCircleIcon color={passwordValid?.state === "ok" ? "success" : "disabled"} sx={{ mr: 1 }}/>) : (<Circle color="disabled" sx={{ mr: 1 }}/>)}

        <Typography variant="body1">
          <FormattedMessage id="password.breachCheck" defaultMessage="Not a known password breach"/>
        </Typography>

        <Tooltip placement="right" title={<Typography variant="body2">
              <FormattedMessage id="password.isBreached" defaultMessage="We validate this password against known breaches in the Have I Been Pwned database. This password has been breached {count} times." values={{
                count: new Intl.NumberFormat().format(passwordValid?.breachCount || 0),
            }}/>
            </Typography>}>
          <InfoOutlinedIcon fontSize="small" sx={{ ml: 1 }}/>
        </Tooltip>
      </Box>
    </Box>);
};
